import React, { useCallback, useMemo, useState,useEffect ,useRef} from 'react';
import {
  MantineReactTable,
  // createRow,
  useMantineReactTable,
} from 'mantine-react-table';
import axiosInstance from '../../../utils/customfetch';
import basepath from '../../../constants/basepath';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';

import { ActionIcon,Flex } from '@mantine/core';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Delete, Edit,Check,Close,Settings } from '@mui/icons-material';
import { data, states } from './makeData';
import { Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
const ConfirmationModal = ({ message, onConfirm, onCancel }) => {
    return (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded shadow-lg">
        <p>{message}</p>
        <div className="mt-4 flex justify-end">
          <button onClick={onCancel} className="btn-cancel">
          <IconButton color="error">
                <Close />
              </IconButton>
           Cancel
          </button>
          <button onClick={onConfirm} className="btn-confirm">
          <IconButton color="success">
                <Check />
              </IconButton>  OK
          </button>
        </div>
      </div>
    </div>
    );
  };

  const DateCellEditor = ({ value, onValueChange }) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          onChange={(date) => onValueChange(date)}
          renderInput={(props) => <TextField {...props} />}
        />
      </LocalizationProvider>
    );
  };  
  
const Roomallot = () => {
  const inputRef = useRef(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState(() => data);
    const [dropdownData, setdropdownData] = useState([]);
    const [studentdropdownData, setstudentdropdownData] = useState([]);
    const [roomdropdownData, setroomdropdownData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [row,setRow]=useState([]);
    const [selectedHostel, setSelectedHostel] = useState('');
    const [selectedStudent,setSelectedStudent]= useState('');
    const [add,addstudent]= useState('');
    const [iseditmode,seteditmodeon]=useState(false);
    
const [selectedRoom, setSelectedRoom] = useState('');
const [join, setjoindate] = useState(getCurrentDate().toString());
const [valueleave, setleave] = useState('');
const [defaultjoiningDate, setdefaultjoiningDate] = useState(null);
const [defaultleavingDate, setdefaultleavingDate] = useState(null);
const [dropdown_data, setdropdown_data] = useState(null);
const [userType, setUserType] = useState('');
  useEffect(() => {
    // Retrieve the user type from local storage
function fetchusertype(){
    const userTypeFromLocalStorage = localStorage.getItem('userType');
    if (userTypeFromLocalStorage) {
      setUserType(userTypeFromLocalStorage);
    }
}
fetchusertype();
  }, []);
useEffect(() => {
  if(dropdown_data){
    const uniqueRoomNos = dropdown_data.reduce((uniqueNos, item) => {
      if (!uniqueNos.includes(item.room_no)) {
        if(item.room_no!='' && item.hostel_id===selectedHostel)
        uniqueNos.push(item.room_no);
      }
      return uniqueNos;
    }, []);
    uniqueRoomNos.sort();
    setroomdropdownData(uniqueRoomNos)
}
  }
 
    ,[selectedHostel]  )  ;
    useEffect(() => {
      // Function to fetch data from the API
      const fetchData = async () => {
   
        try {
          const response = await axiosInstance.get(`${basepath}dropdown-data`);
          console.log(response)
          setdropdown_data(response.dropdown)
          // setTableData(response.getdata);
          // setPlists(myData.Participants); // Set the data to the state
          const uniqueHostelIds = response.dropdown.reduce((uniqueIds, item) => {
            if (!uniqueIds.includes(item.hostel_id)) {
              if(item.hostel_id!='')
              uniqueIds.push(item.hostel_id);
            }
            return uniqueIds;
          }, []);
          // const uniqueRoomNos = response.dropdown.reduce((uniqueNos, item) => {
          //   if (!uniqueNos.includes(item.room_no)) {
          //     if(item.room_no!='')
          //     uniqueNos.push(item.room_no);
          //   }
          //   return uniqueNos;
          // }, []);
          
          // setroomdropdownData(null)
          setdropdownData(uniqueHostelIds)
          setstudentdropdownData(response.students)
      
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      // Call the fetchData function to load data on component mount
      fetchData();
    }, [add]); 

    const handleDeleteClick = () => {
      setShowConfirmation(true);
    };
  
    const handleConfirm = async () => {
      // Handle the delete action here

      tableData.splice(row.index, 1);
      setTableData([...tableData])
      setShowConfirmation(false);
      try {
        const response = await axiosInstance.get(`${basepath}delete_student_allot/${row.original.id}`);
      
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    const handleCancel = () => {
      setShowConfirmation(false);
    };


  const handleCreateNewRow = (values) => {
    tableData.push(values);
    setTableData([...tableData]);
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    
    if (!Object.keys(validationErrors).length) {
      
      // if(!(values.joining_date)){
      //   values.joining_date=defaultjoiningDate
      // }
      // if(!(values.leaving_date)){
      //   values.leaving_date=defaultleavingDate
      // }
      values.joining_date=row.original.joining_date;
      values.leaving_date=row.original.leaving_date;
      tableData[row.index] = values;
      //send/receive api updates here, then refetch or update local table data for re-render
      setTableData([...tableData]);
      const source = values; // Replace this with your actual source data.

        // Create an empty JavaScript object.
        const studentData = {};

// Assign values from the source to the corresponding properties in the JavaScript object.


          studentData.room_id = source.room_id;
          studentData.student_id = source.student_id;
          studentData.joining_date = source.joining_date;
          studentData.leaving_date = source.leaving_date;
studentData.id=row.original.id;


    try {
      await axiosInstance.post(`${basepath}update_student_allotment/${source.student_id}`,studentData);
      values.leaving_date=null;
      tableData[row.index] = values;
      //send/receive api updates here, then refetch or update local table data for re-render
      setTableData([...tableData]);
      addstudent(Math.random().toString(36).substring(2,7));
    } catch (error) {
      values.leaving_date=null;
      tableData[row.index] = values;
      //send/receive api updates here, then refetch or update local table data for re-render
      setTableData([...tableData]);
    
      console.error('Error fetching data:', error);
      addstudent(Math.random().toString(36).substring(2,7));
     
    }
      exitEditingMode(); //required to exit editing mode and close modal
      seteditmodeon(false);
    }
  };

  const handleCancelRowEdits = () => {
    seteditmodeon(false);
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row) => {
  
    setRow(row)
    handleDeleteClick( tableData,row );
      //send api delete request here, then refetch or update local table data for re-render
     
    },
    [tableData],
  );
  const handleSaveCell = async (cell, value) => {
    if (!Object.keys(validationErrors).length) {
    //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here
    tableData[cell.row.index][cell.column.id] = value;
    //send/receive api updates here
    // console.log(cell.row.original)
    const source = cell.row.original; // Replace this with your actual source data.

// Create an empty JavaScript object.
const studentData = {};

// Assign values from the source to the corresponding properties in the JavaScript object.


studentData.room_id = source.room_id;
studentData.student_id = source.student_id;
studentData.joining_date = source.joining_date;
studentData.leaving_date = source.leaving_date;
studentData.id=source.id;

setTableData([...tableData]);

    try {
      await axiosInstance.post(`${basepath}update_student_allotment/${source.student_id}`,studentData);
    
    } catch (error) {
      console.error('Error fetching data:', error);
    }
     //re-render with new data
  }
  };

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      // console.log(cell);
      
      // return {
      //   error: validationErrors[cell?.id],
      //   helperText: validationErrors[cell?.id],
      //   onBlur: (event) => {
      //     const isValid =
      //       cell.column?.id === 'email_id'
      //         ? validateEmail(event.target.value)
      //         : cell.column.id === 'student_name'
      //         ? validateRequired(+event.target.value)
      //         : validateRequired(event.target.value);
      //     if (!isValid) {
      //       //set validation error for cell if invalid
      //       setValidationErrors({
      //         ...validationErrors,
      //         [cell?.id]: `${cell?.column.columnDef.header} is required`,
      //       });
      //     } else {
      //       //remove validation error for cell if valid
      //       delete validationErrors[cell?.id];
      //       setValidationErrors({
      //         ...validationErrors,
      //       });
      //     }
      //   },
      // };
    },
    [validationErrors],
  );

  function validateDateFormat(value) {
    const dateRegex = /^\d{2}-\d{2}-\d{4}$/; // DD-MM-YYYY format
    const currentDate = new Date(); // Current date
    
    if (!value) {
        return null;
    } else if (!dateRegex.test(value)) {
        return 'Invalid Date Format';
    } else {
        // Parse the input date string and compare it with the specific date
        const inputDate = new Date(value.split('-').reverse().join('-'));
        const specificDate = new Date('2023-09-01');
        
        if (inputDate < specificDate) {
            return 'Date is incorrect';
        }
    }
    
    return null; // Validation passed
}


  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(today.getDate()).padStart(2, '0');
    // return `${year}-${month}-${day}`;
    return `${day}-${month}-${year}`;
  }

  const columns = useMemo(
    () => [

      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: true,
        enableEditing: false, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
      {
        accessorKey: 'device_id',
        header: 'Device ID',
        enableColumnOrdering: true,
        enableEditing: false, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
      {
        accessorKey: 'phase',
        header: 'Phase',
        enableColumnOrdering: true,
        enableEditing: false, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
    
      {
        
        accessorKey: 'joining_date',
        header: 'Joining Date',
        
        enableColumnOrdering: true,
        enableEditing: false, //disable editing on this column
        enableSorting: true,
        size: 80,
        
        cellEditor: DateCellEditor,
        // muiTableBodyCellEditTextFieldProps:{

        // }
        mantineEditTextInputProps:({ cell, column, row, table }) => ( {
          error: validationErrors.joining_date, //highlight mui text field red error color
          // value:  cell.value==''?getCurrentDate():cell.value,
       
          placeholder: 'Ex-'+getCurrentDate(),
       
          onClick:(event)=>{
            let date=getCurrentDate();
            // console.log(row.original.joining_date)
         
            // console.log(tableData[cell.row.index][cell.column.id])
            if(event.target.value==null || event.target.value==''){
              
              // console.log(tableData[cell.row.index][cell.column.id]);
              
              event.target.value=date;
              row.original.joining_date=date;
              //  setdefaultjoiningDate(date);
           
              
              
            }
           
          },
          onChange: (event) => {
            
            row.original.joining_date=event.target.value;
            const value = event.target.value;
            //validation logic
            console.log('joining on change')
            setdefaultjoiningDate(null) 
            
            const dateFormatError = validateDateFormat(value);

            if (dateFormatError) {
              setValidationErrors((prev) => ({ ...prev, joining_date: dateFormatError }));
            } else {
              delete validationErrors.joining_date;
              setValidationErrors({ ...validationErrors });
            }
            
          }
        })
      },
      {
        accessorKey: 'leaving_date',
        header: 'Leaving Date',
        enableColumnOrdering: true,
        enableEditing: true, //disable editing on this column
        enableSorting: true,
        size: 80,
        cellEditor: DateCellEditor,
        mantineEditTextInputProps:({ cell, column, row, table })=> ({
          error: validationErrors.leaving_date, //highlight mui text field red error color
          // helperText: validationErrors.leaving_date, //show error message in helper text.
        
          placeholder: 'Ex-'+getCurrentDate(),
          onClick:(event)=>{
            let date=getCurrentDate();
            if(event.target.value==null || event.target.value==''){
              event.target.value=date;
              row.original.leaving_date=date;
           
            }
          },
          onChange: (event) => {
            const value = event.target.value;
            //validation logic
            setdefaultleavingDate(null)
            row.original.leaving_date=event.target.value;
            const dateFormatError = validateDateFormat(value);

            if (dateFormatError) {
              setValidationErrors((prev) => ({ ...prev, leaving_date: dateFormatError }));
            } else {
              delete validationErrors.leaving_date;
              setValidationErrors({ ...validationErrors });
            }
            
          }
        })
      },
      {
        accessorKey: 'student_id',
        header: 'Student ID',
        enableColumnOrdering: true,
        enableEditing: false, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
        // {
      //   accessorKey: 'state',
      //   header: 'State',
      //   muiTableBodyCellEditTextFieldProps: {
      //     select: true, //change to select for a dropdown
      //     children: states.map((state) => (
      //       <MenuItem key={state} value={state}>
      //         {state}
      //       </MenuItem>
      //     )),
      //   },
      // },
   
      {
        accessorKey: 'student_name',
        header: 'Student Name ',
        enableColumnOrdering: true,
        enableEditing: false, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
      {
        accessorKey: 'phone_no',
        header: 'Phone No',
        size: 140,
        enableColumnOrdering: true,
        enableEditing: false, //disable editing on this column
        enableSorting: true,
      
      }
      
    
      
    ],
    [getCommonEditTextFieldProps],
  );


const findstudent = () => {
  // Check if both hostel and room are selected before making the API request

  if (selectedHostel && selectedRoom) {
   
    handleSelectChange();
  }
}
useEffect(() => {
  // This effect will be triggered whenever selectedHostel or selectedRoom changes
  findstudent();
}, [selectedHostel, selectedRoom,add]);

const handleSelectChange = async () => {
  // Make the API request with selectedHostel and selectedRoom values
  try {
    const response = await axiosInstance.get(
      `${basepath}hostel-data/${selectedHostel}/${selectedRoom}`
    );
    // Process the response and update the table data or perform any required actions
    console.log(response);
         setTableData(response.getdata);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
const handleAddStudent = async () => {
  
  if(iseditmode){
    alert("You have unsaved changes")
    return;
  }
  // Check if a student is selected from the Autocomplete
  
  if (!selectedHostel || !selectedRoom || !selectedStudent) {
  
    // Display an error message or handle the scenario where a student is not selected
    return;
  }

  try {
    // Create the data object to be sent in the POST request
    const postData = {
      student_id: selectedStudent, // Replace with the correct property name
      hostel_id:selectedHostel,
      room:selectedRoom
      // Other properties you want to send...
    };

    // Make the POST request to your API endpoint
    const response = await axiosInstance.post(`${basepath}allot`, postData);

    // Handle the response if needed (e.g., show a success message)
    console.log('Student added successfully:', response);

    // Clear the selected student if required
  
    addstudent(Math.random().toString(36).substring(2,7));
    setSelectedStudent(null);
  } catch (error) {
    // Handle errors from the POST request (e.g., show an error message)
    console.error('Error adding student:', error);
    if(error.response.data.message){
    alert(error.response.data.message);
    }else{
      alert(error);
    }
  }
};
  return (
    <div className="grid grid-row ">
   <div className="bg-stone-200 py-1 px-4 sm:px-6 lg:px-8 flex items-center">
  <div className="">
    
    <span className=" text-gray-600 "><Settings fontSize='large'/></span>
  </div>
  <div className="text-left">
    <h1 className="text-3xl font-semibold text-gray-600">Rooms</h1>
  </div>
</div>
  <div className="">
  <div className="flex flex-row gap-4">
  <div className="w-60  p-4">
    <FormControl variant="filled" fullWidth>
      <InputLabel id="demo-simple-select-label">Hostel</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        onChange={(event) => {
            setSelectedHostel(event.target.value)
        }}
        label="Hostel"
        value={selectedHostel}
    
      >
        <MenuItem disabled value="">
            <em>Select Hostel</em>
          </MenuItem> 
        {dropdownData.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
       
      </Select>
    </FormControl>
  </div>
  
  <div className="w-60  p-4">
    <FormControl variant="filled" fullWidth>
      <InputLabel id="demo-simple-select-label">Room</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedRoom}
        onChange={(event) => {
          setSelectedRoom(event.target.value);
      
        }}
        label="Room"
      
      >
          <MenuItem disabled value="">
            <em>Select Room</em>
          </MenuItem>
           {roomdropdownData.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}


    
      </Select>
    </FormControl>
  </div>

  <div className="w-60  p-4">
  <Autocomplete
      disablePortal
      id="combo-box-demo"
    
      options={studentdropdownData}
      value={selectedStudent}
      onChange={(event, value) => {
        setSelectedStudent('')
        if(value)
        setSelectedStudent(value.label)
    }}
      renderInput={(params) => <TextField  variant="filled" {...params} label="Student" />}
    />
     </div>
     <div className="w-28  p-4 flex items-center justify-center">
      <button
        onClick={handleAddStudent}
        className="w-full h-full bg-gray-600 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-300"
      >
        +ADD
      </button>
    </div>
</div>

  </div>
  <div className="ml-4">

    <div style={{ width: '92vw' }}>
     {showConfirmation && (
        <ConfirmationModal
          message="Are you sure you want to delete?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      <MantineReactTable 
      initialState={{ columnVisibility: { id: false } }}
    
     
        columns={columns}
        data={tableData}
        enableColumnOrdering
        createDisplayMode='row' // ('modal', and 'custom' are also available)
        editDisplayMode='row' // ('modal', 'cell', 'table', and 'custom' are also available)
      enableEditing
      onEditingRowSave={handleSaveRowEdits}
      onEditingRowCancel={handleCancelRowEdits}
      enableStickyHeader
      enableStickyFooter
      mantineTableContainerProps={{ sx: { maxHeight: '42vh' } }}
      // muiTableBodyCellEditTextFieldProps={({ cell }) => ({
      //   //onBlur is more efficient, but could use onChange instead
      //   onBlurCapture: (event) => {
      //     getCommonEditTextFieldProps()
      //     handleSaveCell(cell, event.target.value);
      //   },
      // })}
      // renderBottomToolbarCustomActions={() => (
      //   <Typography sx={{ fontStyle: 'italic', p: '0 1rem' }} variant="body2">
      //     Double-Click a Cell to Edit
      //   </Typography>
      // )}
      
      
        // enableRowActions
        renderRowActions={({ row,table }) => (
          <Flex gap="md">
            
           
            {userType === 'sadmin' && (

            <Tooltip  title="Delete">
              <ActionIcon color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </ActionIcon>
            </Tooltip>
            )}
              {(userType === 'sadmin' || userType === 'admin') && (
            <Tooltip  title="Edit">
              <ActionIcon color="info" className='z-5' onClick={() => { seteditmodeon(true);setdefaultjoiningDate('');setdefaultleavingDate('');table.setEditingRow(row)}}>
                <Edit />
              </ActionIcon>
            </Tooltip>
              )}
          </Flex>
        )}
        
        // renderTopToolbarCustomActions={() => (
        //   <Button
            
        //     onClick={() => setCreateModalOpen(true)}
        //     variant="contained"
        //     color='success'
        //   >
        //     + Add
        //   </Button>
        // )}
      />
      <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        tableData={tableData}
        iseditmode={iseditmode}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
    </div>
    </div>
  
  
</div>
  );
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit,tableData,iseditmode }) => {
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {}),
  );
  const [validationErrors, setValidationErrors] = useState({});
  

  const handleSubmit = async () => {
    //put your validation logic here
    // onSubmit(values);
    // console.log(values);
    // let new_person={student_id:studentID, student_name:studentName, emailid:emailID, phone_no:phoneNumber, stud_address:studentAddress,  branch_id:branchID}

    const { student_name, phone_no,student_id} = values;
    let errors = {};
    if (tableData.some((row) => row.student_id === student_id)) {
      errors.student_id = 'Student ID already exists';
    }
    if (!validateRequired(student_id)) {
      errors.student_id = 'Student Id is required';
    }

    if (!validateRequired(student_name)) {
      errors.student_name = 'Name is required';
    }

   

    if (!validatePhoneNumber(phone_no)) {
      errors.phone_no = 'Invalid phone number format';
    }
   
    if(iseditmode){
      alert("You have Unsaved Changes");
      return
    }
  

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }else{
      setValidationErrors({});
      errors={}
      if(values){
        try {
          onSubmit(values);
          errors={}
      
          await axiosInstance.post(`${basepath}students`,values);
          // setValues({});
          setValues({
            student_name: '',
          
            phone_no: '',
     
            student_id: '',
          });
          setValidationErrors({});
         
        } catch (error) {
          console.error('Error fetching data:', error);
          // setValues({});
          
          setValidationErrors({});
        }
        

    }
   
  }

    onClose();
  };

  return (
    <Dialog open={open}>
    <DialogTitle textAlign="center">
    <AddCircleIcon color='success' sx={{ fontSize: 36 }} />
      Add New Student
    </DialogTitle>
    <DialogContent className="mt-2">
      <form onSubmit={(e) => e.preventDefault()}>
        <Stack
          sx={{
            width: '100%',
            minWidth: { xs: '300px', sm: '360px', md: '400px' },
            gap: '2.5rem',
          }}
        >
       <div className="w-full mt-4 grid grid-cols-2 gap-4">
  {columns.map((column) => {
    return column.enableEditing ? (
      <TextField
        key={column.accessorKey}
        label={column.header}
        name={column.accessorKey}
        className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
        onChange={(e) =>
          setValues({ ...values, [e.target.name]: e.target.value })
        }
        error={!!validationErrors[column.accessorKey]}
       
      />
    ) : (
      null
    );
  })}
</div>
        </Stack>
      </form>
    </DialogContent>
    <DialogActions sx={{ p: '1.25rem' }}>
      <Button onClick={onClose} startIcon={<Close />}>Cancel</Button>
      <Button onClick={handleSubmit} variant="contained" startIcon={<SaveIcon />}>
        Save
      </Button>
    </DialogActions>
  </Dialog>
  );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
const validateAge = (age) => age >= 18 && age <= 50;
  // Validation Functions


  const validatePhoneNumber = (phoneNumber) =>
    /^(\+\d{1,2}\s?)?(\(\d{3}\)|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phoneNumber);

export default Roomallot;