import React, { createContext, useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route,Redirect ,Navigate,HashRouter} from 'react-router-dom';
import './App.css';
import LandingPage from './pages/landing';
import Auth from './pages/auth';
import Login from './components/login';
import SignUp from './components/signup';
import Dashboard from './pages/dashboard';
import Content from './components/content';
import Home from './components/sidebarmenu/Home'; 
import Billing from './components/sidebarmenu/billing';
import Energy from './components/sidebarmenu/energy';
import {useLoader} from './context/loadercontext';
import {LoaderProvider} from './context/loadercontext';
import Loader from './components/loader';
import  Settings  from './components/sidebarmenu/settings';
import Example from './components/sidebarmenu/student_records/Example';
import Room from './components/sidebarmenu/room';
import Report from './components/sidebarmenu/report';
import Emptyroom from './components/sidebarmenu/emptyroom';
import Studentcount from './components/sidebarmenu/studentcount';
import Devicedetails from './components/sidebarmenu/devicedetails';




function Logout({ setAuth }) {
  useEffect(() => {
    // Clear local storage here
    localStorage.clear();
    setAuth(false);
  }, [setAuth]);

  return <Navigate to="/" />;
}
function App() {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `Sawangi Biller`;
  });
  const [auth,setauth]=useState(false);
  useEffect(()=>{
    function isLoggedIn() {
      // alert((localStorage.getItem('login_status') === '1')?true:false);
      setauth((localStorage.getItem('login_status') === '1')?true:false);
      if((localStorage.getItem('login_status') === '1')?true:false){
      return  <Navigate to="logout" />
      }
  
  }
  isLoggedIn();
},[])

  return (
    <HashRouter>
    {/* <Router> */}
      
       
    <div>
      
    
    <div id="loader" className='hidden' >
    <div className="loader-container">
      <div className="loader"></div>
    </div>
    </div>   
 

  
      <Routes>
          {/* <Route path="/" element={<Auth />}/>
          <Route path="/auth/*" element={<Auth />} >
                 <Route path="" element={<Login />} />
                 <Route path="login" element={<Login />} />
                 <Route path="signup" element={<SignUp />} />
          </Route>
          <Route path="/dashboard/" element={<Dashboard />} >
      
          <Route path="/dashboard/content/*" element={< Content/>} >
                  <Route path=""  element={<Energy />} />
                  <Route path="energy" element={<Energy />} />
                  <Route path="home" element={<Home />} />
                  <Route path="billing" element={<Billing />} />
                  <Route path="settings" element={<Settings />} />
               

               </Route>
           </Route>     */}
        
        <Route path="/" element={<Auth />}/>
        <Route path="/logout" element={<Logout  setAuth={setauth} />}/>
        
        <Route path="/auth/*" element={!auth?<Auth />:<Navigate to='/dashboard/content/settings' />} >
                 <Route path="" element={<Login setAuth={setauth}/>} />
                 <Route path="login" element={<Login setAuth={setauth}/>} />
                 <Route path="signup" element={<SignUp />} />
          </Route>
          <Route path="/dashboard/" element={!auth?<Navigate to='/'/>:<Dashboard />} >
      
          <Route path="/dashboard/content/*" element={!auth?<Navigate to='/'/>:< Content/>} >
        
         
                <Route index element={!auth?<Navigate to='/'/>:<Navigate to='/dashboard/content/settings' />} />
                <Route path="energy" element={<Energy />} />
                <Route path="home" element={<Home />} />
                <Route path="room" element={<Room />} />
                <Route path="billing" element={<Billing />} />
                <Route path="settings" element={<Settings />} />
                <Route path="report" element={<Report />} />
                <Route path="emptyroom" element={<Emptyroom/>} />
                <Route path="studentcount" element={<Studentcount/>} />
                <Route path="devicedetails" element={<Devicedetails/>} />



      
               

               </Route>
          </Route> 
    
       
      </Routes>

      {/* <Example/> */}
    </div>
     {/* </Router> */}
  </HashRouter>
  

  
  );
}


export default App;
