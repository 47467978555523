import Footer from "../components/footer";
import { Link ,useNavigate,useLocation} from 'react-router-dom';
import React, { useState } from 'react';
import { Users,User, Settings, LogOut,Home ,ChevronRight,ChevronLeft} from 'react-feather';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';


const Sidebar= () =>{
    const navigate = useNavigate();
    const location = useLocation();
    
    const [userdata,setuserdata]=useState(JSON.parse(localStorage.getItem('loginResponse')));
    const handleLogout = () => {
        // Add your logout logic here
        localStorage.clear()
        navigate('/')

      };


      const [showMenuNames, setShowMenuNames] = useState(false);

      const handleToggleMenuNames = () => {
        setShowMenuNames(!showMenuNames);
      };

      
      
      //  console.log(userdata);

return (

  <div  className="flex flex-col shadow-md items-center w-full h-full" id="sidebarContainer">
    <nav className="flex-grow ">
    <div id="sidebar" className="flex flex-col items-center w-full h-full overflow-hidden text-black bg-d1fEC5 rounded ">
  
  <div className="w-full px-2 sidebarContent  ">
  <Tooltip  title="Show/Hide" placement="right" arrow>
    <Link className="flex items-center w-full px-5 mt-3 " href="#">
   
      <button className=" flex space-x-4 items-center " onClick={handleToggleMenuNames}>
    {showMenuNames ? 
    <div className="flex items-center space-x-4  ">     
     <span  className=" hide inline-flex items-center stroke-current h-4 w-4 text-xl icon-text  bx bx-chevron-left"></span> 
     <span className="hide ml-2 text-sm font-medium icon-text">Hide</span>
     </div>:    
       <span id="show" className="inline-flex items-center stroke-current h-4 w-4 text-xl   bx bx-chevron-right"></span>}
  </button>
  
    </Link>
    </Tooltip>
 
  
  </div>


  <div className="w-full px-1 flex-grow sidebarContent">
    <div className="flex flex-col items-center w-full mt-3  ">
      {/* <Link className="flex items-center w-full h-12 px-5 mt-2 rounded hover:bg-gray-300" href="">
        <span className="inline-flex items-center stroke-current h-4 w-4 text-xl  bx bx-home"></span>
        <span className="ml-2 text-sm font-medium icon-text"> {showMenuNames && <span>Dashboard</span>}</span>
      </Link> */}

     
      {/* <Link className="flex items-center w-full h-12 px-5 mt-2 rounded hover:bg-gray-300" href="#">
        <span className="inline-flex items-center stroke-current h-4 w-4 text-xl  bx bx-bar-chart-alt-2"></span>
        <span className="ml-2 text-sm font-medium icon-text">{showMenuNames && <span>Reports</span>}</span>
      </Link>  */}
    </div>
    <div className="flex flex-col items-center w-full mt-2 border-t-2  border-black ">
    <Tooltip  title="Students Records:Show student details " placement="right" arrow>
      
      <Link className={`flex items-center w-full h-12 px-5 mt-2 rounded-full ${
                  location.pathname === '/dashboard/content/settings' ? 'bg-gray-300' : 'hover:bg-gray-300'
                }`}
       to="/dashboard/content/settings">
        
        <span className="inline-flex items-center stroke-current h-4 w-4 text-xl  bx bx-user"></span>
        <span className="ml-2 text-sm font-medium icon-text">{showMenuNames && <span>Students</span>}</span>
       
      </Link>
      </Tooltip>
      <Tooltip  title="Rooms Records : Can allocate or Remove Students from Room" placement="right" arrow>
      <Link className={`flex items-center w-full h-12 px-5 mt-2 rounded-full ${
                  location.pathname === '/dashboard/content/room' ? 'bg-gray-300' : 'hover:bg-gray-300'
                }`}  to="/dashboard/content/room">
                 
        <span className="inline-flex items-center stroke-current h-4 w-4 text-black  bx bx-buildings"></span>
        <span className="ml-2 text-sm font-medium icon-text">{showMenuNames && <span>Rooms</span>}</span>
       
      </Link>
      </Tooltip>
      <Tooltip  title="Energy Consumption : See the Energy Consumption by any Room in any time period" placement="right" arrow>
      <Link 
      
      className={`flex items-center w-full h-12 px-5 mt-2 rounded-full ${
        location.pathname === '/dashboard/content/energy' ? 'bg-gray-300' : 'hover:bg-gray-300'
      }`}
      to="/dashboard/content/energy">
      
        <span className="inline-flex items-center stroke-current h-4 w-4 text-xl  bx bx-pulse"></span>
        <span className="ml-2 text-sm font-medium icon-text">{showMenuNames && <span>Energy</span>}</span>
        
      </Link>
      </Tooltip>

      <Tooltip  title="Error Report : Show the list of Students who a Student who data incomplete" placement="right" arrow>
      <Link 
      
      className={`flex items-center w-full h-12 px-5 mt-2 rounded-full ${
        location.pathname === '/dashboard/content/report' ? 'bg-gray-300' : 'hover:bg-gray-300'
      }`}
      to="/dashboard/content/report">
      
        <span className="inline-flex items-center stroke-current h-4 w-4 text-xl  bx bx-book"></span>
        <span className="ml-2 text-sm font-medium icon-text">{showMenuNames && <span>Error Report</span>}</span>
        
      </Link>
      </Tooltip>
      <Tooltip  title="Empty Room Report : Genrate list of empty Room show in Consumption" placement="right" arrow>
      <Link 
      
      className={`flex items-center w-full h-12 px-5 mt-2 rounded-full ${
        location.pathname === '/dashboard/content/emptyroom' ? 'bg-gray-300' : 'hover:bg-gray-300'
      }`}
      to="/dashboard/content/emptyroom">
      
        <span className="inline-flex items-center stroke-current h-4 w-4 text-xl  bx bx-building-house"></span>
        <span className="ml-2 text-sm font-medium icon-text">{showMenuNames && <span>Empty Room Report</span>}</span>
        
      </Link>
      </Tooltip>
      <Tooltip  title="Student Count Report : Show the number of student room, vacant room, on any day" placement="right" arrow>
      <Link 
      
      className={`flex items-center w-full h-12 px-5 mt-2 rounded-full ${
        location.pathname === '/dashboard/content/studentcount' ? 'bg-gray-300' : 'hover:bg-gray-300'
      }`}
      to="/dashboard/content/studentcount">
        <div className="inline-flex items-center stroke-current h-4 w-4 text-xl rounded">
        <Users size={20} />
        </div>
       
        {/* <span className="inline-flex items-center stroke-current h-4 w-4 text-xl  bx bx-user-badge"></span> */}
        <span className="ml-2 text-sm font-medium icon-text">{showMenuNames && <span>Student Count Report</span>}</span>
        
      </Link>
      </Tooltip>
      
      <Tooltip  title="Device Details Report : Details of the meter ID and phase connection" placement="right" arrow>
      <Link className={`flex items-center w-full h-12 px-5 mt-2 rounded-full ${
                  location.pathname === '/dashboard/content/devicedetails' ? 'bg-gray-300' : 'hover:bg-gray-300'
                }`}  to="/dashboard/content/devicedetails">
                 
        <span className="inline-flex items-center stroke-current h-4 w-4 text-xl  bx bx-tachometer"></span>
        <span className="ml-2 text-sm font-medium icon-text">{showMenuNames && <span>Device Details Report</span>}</span>
       
      </Link>
      </Tooltip>
      

      {/* <Link className={`flex items-center w-full h-12 px-5 mt-2 rounded ${
                  location.pathname === '/dashboard/content/billing' ? 'bg-gray-300' : 'hover:bg-gray-300'
                }`}
       to="/dashboard/content/billing">
        <span className="inline-flex items-center stroke-current h-4 w-4 text-xl  bx bx-wallet"></span>
        
        <span className="ml-2 text-sm font-medium icon-text">{showMenuNames && <span>Billing</span>}</span>
      </Link> */}
   
   
    </div>
  </div>

  <div className="w-full px-1 bg-d1fEC5 sidebarContent">
     {/* <Link className="flex items-center px-5 w-full h-16 mt-2 hover:bg-gray-300 rounded" href="#">
      <span className="inline-flex items-center stroke-current h-4 w-4 text-xl  bx bx-user-circle"></span>
      <span className="ml-2 text-sm font-medium icon-text"> {showMenuNames && <span>Account</span>}</span>
    </Link> */}
     <Tooltip  title="Logout" placement="right" arrow>
    <Link
    onClick={handleLogout}
    className="flex items-center w-full h-12 px-5 mt-2 mb-2 rounded hover:bg-gray-300 rounded-full"
    to="/logout"
  >
    <div className="inline-flex items-center stroke-current h-4 w-4 text-xl rounded">
      <LogOut size={20} />
    </div>
    <span className="ml-2 text-sm font-medium icon-text"> {showMenuNames && <span>Logout</span>}</span>
   
    
  </Link>
  </Tooltip> 
  </div>
</div>




</nav>










</div>
)

  }

  export default Sidebar;





