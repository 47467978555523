import React from 'react';
import Roomallot from './room_records/roomallot';


const Room = () => {

 

  return (
    <div style={{height:'80vh'}}>
       <Roomallot/>
    </div>
  );
};

export default Room;
