const Footer = () =>{
    return (
<footer className=" inline-flex justify-center bg-stone-200 px-4 py-2" style={{zIndex:'1000'}}>
 
    <p >© 2023 Copyright: Conceptualized, Designed, Installed & Maintained By Heta Datain</p>
</footer>

    )}
export default Footer;    

