import React, { useCallback, useMemo, useState,useEffect } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import  basepath  from './../../../src/constants/basepath'
import axiosInstance from '../../utils/customfetch';
import { useLoader } from '../../context/loadercontext';
import { Box, Button } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import { ExportToCsv } from 'export-to-csv'; 
import{Bolt} from '@mui/icons-material';
import { jsPDF } from 'jspdf'; //or use your library of choice here
import autoTable from 'jspdf-autotable';
import {
  MantineReactTable,
  // createRow,
  useMantineReactTable,
} from 'mantine-react-table';
import { Book } from '@mui/icons-material';


const Report = () => {
    
  const [roomData, setRoomData] = useState([]);
  const [hostelData, setHostelData] = useState([]);
  const [startselectedDate, setstartSelectedDate] = useState('');
  const [endselectedDate, setendSelectedDate] = useState('');
  const [selectedRoom, setSelectedRoom] = useState('');
  const [selectedHostel, setSelectedHostel] = useState('');
  const [selectRate,setselectRate]=useState('14');
  const [tableData2, settableData22] = useState([]);

  useEffect(() => {
    handleSubmit()
  }, []);

  const handleRoomChange = (event) => {
    console.log(event.target.value)
    setSelectedRoom(event.target.value);
  };

  const handleHostelChange = (event) => {
    setSelectedHostel(event.target.value);
  };

  const handlestartDateChange = (event) => {
    setstartSelectedDate(event.target.value);
  };
  const handleendDateChange = (event) => {
    setendSelectedDate(event.target.value);
  };

  const handleSubmit = async () => {
   
    try {
      
     
// alert(device_id)
      // Make GET request to fetch hourly data
    
      const response = await axiosInstance.get(`${basepath}students-with-rooms`);
    // console.log(`${basepath}hourlydata/${device_id}/${selectedDate}/${phase}/${selectedHostel}`)
    // const response = await axiosInstance(`${basepath}hourlydata/33/${selectedDate}/1/laxmi`);
    // console.log(response)
      const data = await response;
      settableData22(data);
      document.getElementById('tableContainer').classList.remove('hidden');
      
    } catch (error) {
      console.log(error);
    }
  };



  const columns = useMemo(
    () => [
      {
        accessorKey: 'student_id',
        header: 'Student ID',
        enableColumnOrdering: true,
        enableEditing: false, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
      {
        accessorKey: 'student_name',
        header: 'Student Name',
        enableColumnOrdering: true,
        enableEditing: false, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
      {
        accessorKey: 'hostel_id',
        header: 'Hostel',
        enableColumnOrdering: true,
        enableEditing: true, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
      {
        accessorKey: 'room_no',
        header: 'Room No',
        enableColumnOrdering: true,
        enableEditing: true, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
      {
        accessorKey: 'joining_date',
        header: 'Joining Date',
        enableColumnOrdering: true,
        enableEditing: true, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
      
     
    ])
    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      headers: columns.map((c) => c.header),
    };
    
    const csvExporter = new ExportToCsv(csvOptions);
    
    const handlepdfExportRows = (rows) => {
      const doc = new jsPDF();
      const tableData = rows.map((row) => Object.values(row.original));
      const tableHeaders = columns.map((c) => c.header);

      let data=rows.map((row) => row.original);
      const columnsToExport = columns.map((c) => c.accessorKey);
       // console.log(columnsToExport)
      // Extract data for the selected columns from each row
      const selectedData = data.map((row) =>
      {//console.log(row);
      return  columnsToExport.map((columnName) => row[columnName])
    });
  
      autoTable(doc, {
        head: [tableHeaders],
        body: selectedData,
      });
  
      doc.save('Energy_'+new Date().toLocaleString()+'.pdf');
    };
  
      const handleExportRows = (rows) => {
        // csvExporter.generateCsv(rows.map((row) => row.original));
        let data=rows.map((row) => row.original);
        const columnsToExport = columns.map((c) => c.accessorKey);
         // console.log(columnsToExport)
        // Extract data for the selected columns from each row
        const selectedData = data.map((row) =>
        {//console.log(row);
        return  columnsToExport.map((columnName) => row[columnName])
      });
       //console.log(selectedData)
        // Generate CSV with the selected data
        csvExporter.generateCsv(selectedData);
      };
    
      const handleExportData = () => {
        csvExporter.generateCsv(tableData2);
      };

  return (
    <>
    <div className="bg-stone-200 py-1 px-4 sm:px-6 lg:px-8 flex items-center">
    <div className="">
      <span className=" text-gray-600 "><Book fontSize='small'/></span>
    </div>
    <div className="text-left">
      <h1 className="text-3xl font-semibold text-gray-600">Error Report</h1>
    </div>
  </div>
    <div  className='p-4'>
      
      {/* <h2 className="text-2xl font-bold mb-4 text-gray-500">Energy</h2> */}
      

      {/* <div id="tableContainer" className="w-full h-60vh overflow-auto custom-div hidden ">
        <table className="w-full bg-white border border-gray-300 shadow-md rounded-lg divide-y divide-gray-200 mt-4">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2 text-left border">Hostel</th>
              <th className="px-4 py-2 text-left border">Room</th>
              <th className="px-4 py-2 text-left border">Start</th>
              <th className="px-4 py-2 text-left border">End</th>
              <th className="px-4 py-2 text-left border">Consumption</th>
            </tr>
          </thead>
          <tbody id="tableBody" className="w-full">
            {tableData2.map((record) => (
              <tr key={record.hour}>
                <td className="px-4 py-2 border">{record.hostel_id}</td>
                <td className="px-4 py-2 border">{record.room_no}</td>
                <td className="px-4 py-2 border">{record.endreadings}</td>
                <td className="px-4 py-2 border">{record.startreadings}</td>
                <td className="px-4 py-2 border">{record.total_consumption_kWh}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}

<MantineReactTable  className=""
     
      enableStickyHeader
      enableStickyFooter
  
      mantineTableContainerProps={{ sx: { maxHeight: '58vh' },xl: { maxHeight: '48vh' } }}
      
        columns={columns}
        data={tableData2}
        enableColumnOrdering
        createDisplayMode='row' // ('modal', and 'custom' are also available)
        editDisplayMode='row' // ('modal', 'cell', 'table', and 'custom' are also available)
        
  
      />
    </div>
    </>
  );
};

export default Report;
