import React, { useState } from 'react';
import basepath from '../constants/basepath';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/customfetch';


const Login = ({ setAuth }) => {
  const navigate = useNavigate();
  
  const [formState, setFormState] = useState({
    username: '',
    password: '',
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState('Login');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate form fields
    const validationErrors = {};
    if (!formState.username) {
      validationErrors.username = 'Username or Email is required';
    }
    if (!formState.password) {
      validationErrors.password = 'Password is required';
    }

    // Check if any validation errors occurred
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Process the login logic here
    setLoading('Wait....');
    // navigate('/dashboard/content/energy');
    
    axiosInstance.post(`${basepath}login`,formState)
      .then((data) => {
        // Handle the response from the server
        console.log('Login response:', data);
        localStorage.setItem('userType', data.usertype)
        setAuth(true);
        navigate('/dashboard/content/');
      })
      .catch((error) => {
        // Handle any error that occurred during the request
        setLoading('Retry to Login');
        alert("Username/Password is invalid");
        console.error('Login error:', error);
      });
  };

  return (
    <div className="flex items-center justify-center h-screen" style={{ backgroundImage: 'url("'+window.location.origin+'/background.jpg")', backgroundSize: 'cover'  }}>
      <form className="bg-white drop-shadow-2xl rounded px-8 pt-6 pb-8 w-96 upblur"   onSubmit={handleSubmit}>
       <div className="blockico">
          <img src={window.location.origin+'/jnmc.png'} alt=""  />
       </div>
       <div className="mb-6 ">
      
        <div>
        <h2 className="text-2xl font-bold mb-6">Login</h2>
       </div>
      </div>
       
        <div className="mb-6">
          
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email:
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            name="username"
            value={formState.username}
            onChange={handleInputChange}
            placeholder="Enter your email"
          />
          {errors.username && <p className="text-red-500 text-xs italic">{errors.username}</p>}
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Password:
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            name="password"
            value={formState.password}
            onChange={handleInputChange}
            placeholder="Enter your password"
          />
          {errors.password && <p className="text-red-500 text-xs italic">{errors.password}</p>}
        </div>
        <div className="flex items-center justify-center">
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            {loading}
          </button>
        </div>
        <div className="flex items-center justify-center mt-4" >
       <h4>Student Managment System</h4>
        </div>
        <div className="flex items-center justify-center mt-4" >
          <img src={window.location.origin+"/hetadatain.png"}  className="h-8 w-38" alt="" />
        </div>
      </form>

    </div>
  );
};

export default Login;
