import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import axiosInstance from '../../utils/customfetch';
import basepath from '../../constants/basepath';
import{Bolt} from '@mui/icons-material';
function HostelTable({ data }) {
  return (
    <div className="h-60vh overflow-y-scroll max-h-[60vh]">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-green-100">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Hostel</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Rooms</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Room occupied</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Room vacant</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Student Count</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((hostel, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">{hostel.hostel_id}</td>
              <td className="px-6 py-4 whitespace-nowrap">{hostel.total_room_count}</td>
              <td className="px-6 py-4 whitespace-nowrap">{hostel.room_count}</td>
              <td className="px-6 py-4 whitespace-nowrap">{hostel.room_count_difference}</td>
              <td className="px-6 py-4 whitespace-nowrap">{hostel.student_count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}






function StudentCount() {
  const [hostelData, setHostelData] = useState([]);
  const [startselectedDate, setstartSelectedDate] = useState('');
  

  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch data from the API
        const response = await axiosInstance.get(`${basepath}hostel-stats/`+startselectedDate);
        setHostelData(response); // Assuming the data is in response.data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    fetchData();
  }, [startselectedDate]);


  useEffect(() => {
    // Initialize flatpickr datepicker
    flatpickr('#startdatepicker', {
      dateFormat: 'Y-m-d', // The format you want the date to be sent to the API
      onChange: function(selectedDates, dateStr, instance) {
        // Your custom function to be invoked when a date is selected
        console.log('Date selected:', dateStr);
        setstartSelectedDate(dateStr);
        // You can call other functions or perform additional actions here
      }
    });

    });

  return (
    <>
    <div className="bg-stone-200 py-1 px-4 sm:px-6 lg:px-8 flex items-center ">
    <div className="">
      <span className=" text-gray-600 "><Bolt fontSize='large'/></span>
      
    </div>
    <div className="text-left">
      <h1 className="text-3xl font-semibold text-gray-600">Student Count</h1>
    </div>
  </div>
    <div className='p-4'>
      
    <div className="grid grid-cols-4 gap-4 mb-2">
      <div className="w-full">
          <label htmlFor="datepicker" className="block mb-2">
            Select Date:
          </label>
          <div className="relative">
            <input
              type="text"
              id="startdatepicker"
              className="w-full px-4 py-2 pl-10 border rounded"
              
            />
            <span className="absolute top-0 left-0 flex items-center h-full pl-3">
              {/* Replace the 'icon-code' with the actual icon class or SVG code */}
              <i className="bx bxs-calendar text-gray-500"></i>
            </span>
          </div>
        </div>
   
    </div>
    <HostelTable data={hostelData} />
    </div>
    </>
  );
}

export default StudentCount;

