import React, { useState ,useEffect} from 'react';

import _ from 'lodash';
import basepath from '../../constants/basepath';
import axiosInstance from '../../utils/customfetch';
import Example from './student_records/Example';



const Settings = () => {
 

  return (
    // 
    <div  style={{height:'80vh'}}>
     <Example />
     </div>
  );
};

export default Settings;
