// axiosInstance.js

import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
 
});

// Create a variable to keep track of the loading state
let loading = false;

// Function to show/hide the loader
const toggleLoader = (showLoader) => {
  loading = showLoader;
  const loaderElement = document.getElementById('loader');
  if (loaderElement) {
    loaderElement.style.display = loading ? 'block' : 'none';
  }
};

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Show loader before the request is sent
    toggleLoader(true);
    return config;
  },
  (error) => {
    // Hide loader on request error
    toggleLoader(false);
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Hide loader when the response is received
    toggleLoader(false);
    // console.log(response.data)
    return response.data;
  },
  (error) => {
    // Hide loader on response error
    toggleLoader(false);
    return Promise.reject(error);
  }
);

export default axiosInstance;
