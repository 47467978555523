import React, { useState } from 'react';

const Billing = () => {
  const [totalAmount, setTotalAmount] = useState(0);
  const [discount, setDiscount] = useState(0);

  const handleTotalChange = (event) => {
    setTotalAmount(Number(event.target.value));
  };

  const handleDiscountChange = (event) => {
    setDiscount(Number(event.target.value));
  };

  const calculateFinalAmount = () => {
    return totalAmount - discount;
  };

  return (
    <div>
      <h2>Billing Details</h2>
      <div>
        <label>Total Amount:</label>
        <input type="number" value={totalAmount} onChange={handleTotalChange} />
      </div>
      <div>
        <label>Discount:</label>
        <input type="number" value={discount} onChange={handleDiscountChange} />
      </div>
      <div>
        <h3>Final Amount: {calculateFinalAmount()}</h3>
      </div>
    </div>
  );
};

export default Billing;
