import React from 'react';
import  {Outlet} from 'react-router-dom';
const Content = () => {
  return (
    <div className="content flex flex-col flex-grow " >
      
      <Outlet />
    </div>
  );
};

export default Content;