import React, { useCallback, useMemo, useState,useEffect } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import  basepath  from './../../../src/constants/basepath'
import axiosInstance from '../../utils/customfetch';
import { useLoader } from '../../context/loadercontext';
import { Box, Button } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import { ExportToCsv } from 'export-to-csv'; 
import{Bolt} from '@mui/icons-material';
import { jsPDF } from 'jspdf'; //or use your library of choice here
import autoTable from 'jspdf-autotable';
import {
  MantineReactTable,
  // createRow,
  useMantineReactTable,
} from 'mantine-react-table';

const Energy = () => {
    
  const [roomData, setRoomData] = useState([]);
  const [hostelData, setHostelData] = useState([]);
  const [startselectedDate, setstartSelectedDate] = useState('');
  const [endselectedDate, setendSelectedDate] = useState('');
  const [selectedRoom, setSelectedRoom] = useState('');
  const [selectedHostel, setSelectedHostel] = useState('');
  const [selectRate,setselectRate]=useState('14');
  const [tableData2, settableData22] = useState([]);
  const [userType, setUserType] = useState('');
  useEffect(() => {
    // Retrieve the user type from local storage
function fetchusertype(){
    const userTypeFromLocalStorage = localStorage.getItem('userType');
    if (userTypeFromLocalStorage) {
      setUserType(userTypeFromLocalStorage);
    }
}
fetchusertype();
  }, []);
  useEffect(() => {
    // Initialize flatpickr datepicker
    flatpickr('#startdatepicker', {
      dateFormat: 'Y-m-d', // The format you want the date to be sent to the API
      onChange: function(selectedDates, dateStr, instance) {
        // Your custom function to be invoked when a date is selected
        console.log('Date selected:', dateStr);
        setstartSelectedDate(dateStr);
        // You can call other functions or perform additional actions here
      }
    });
    flatpickr('#enddatepicker', {
      dateFormat: 'Y-m-d', // The format you want the date to be sent to the API
      onChange: function(selectedDates, dateStr, instance) {
        // Your custom function to be invoked when a date is selected
        console.log('Date selected:', dateStr);
        setendSelectedDate(dateStr);
        // You can call other functions or perform additional actions here
      }
    });

    
  

    // fetch data for dropdowns using AJAX
    const fetchRoomData = async () => {
      try {
        const response = await axiosInstance(`${basepath}room-options`);
        const data = await response;
        setRoomData(data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchHostelData = async () => {
      try {
        const response = await axiosInstance(`${basepath}hostel-options`);
        const data = await response;
        
        setHostelData(data);
      } catch (error) {
        console.log(error);
      }
    };

    // fetchRoomData();
    fetchHostelData();
  }, []);

  useEffect(()=>{
    const fetchRoomData = async () => {
      try {
        const response = await axiosInstance(`${basepath}room-options/`+selectedHostel);
        const data = await response;
        setRoomData(data);
      } catch (error) {
        console.log(error);
      }
    };
     fetchRoomData();

  },[selectedHostel]);

  const handleRoomChange = (event) => {
    console.log(event.target.value)
    setSelectedRoom(event.target.value);
  };

  const handleHostelChange = (event) => {
    setSelectedHostel(event.target.value);
  };

  const handlestartDateChange = (event) => {
    setstartSelectedDate(event.target.value);
  };
  const handleendDateChange = (event) => {
    setendSelectedDate(event.target.value);
  };

  const handleSubmit = async () => {
    if (!startselectedDate || !endselectedDate || !selectedHostel) {
      alert('Please select Hostel and Date.');
      return;
    }
    try {
      
     
// alert(device_id)
      // Make GET request to fetch hourly data
     let postdata={
        hostel_id:selectedHostel,
        start_date:startselectedDate,
        end_date:endselectedDate,
        room_id:selectedRoom,
        rate:selectRate
      }
      console.log(postdata)
      const response = await axiosInstance.post(`${basepath}room-consumption`,postdata);
    // console.log(`${basepath}hourlydata/${device_id}/${selectedDate}/${phase}/${selectedHostel}`)
    // const response = await axiosInstance(`${basepath}hourlydata/33/${selectedDate}/1/laxmi`);
    // console.log(response)
      const data = await response;
      settableData22(data);
      document.getElementById('tableContainer').classList.remove('hidden');
      
    } catch (error) {
      console.log(error);
    }
  };



  const columns = useMemo(
    () => [
      {
        accessorKey: 'hostel_id',
        header: 'Hostel',
        enableColumnOrdering: true,
        enableEditing: false, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
      {
        accessorKey: 'room_no',
        header: 'Room',
        enableColumnOrdering: true,
        enableEditing: false, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
      {
        accessorKey: 'startreadings',
        header: 'Start reading',
        enableColumnOrdering: true,
        enableEditing: true, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
      {
        accessorKey: 'endreadings',
        header: 'End reading',
        enableColumnOrdering: true,
        enableEditing: true, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
      {
        accessorKey: 'total_consumption_kWh',
        header: 'Kwh Consumption',
        enableColumnOrdering: true,
        enableEditing: true, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
      {
        accessorKey: 'total_consumption_kWh_multiplied',
        header: 'Amount(rs)',
        enableColumnOrdering: true,
        enableEditing: true, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
     
    ])
    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      headers: columns.map((c) => c.header),
    };
    
    const csvExporter = new ExportToCsv(csvOptions);
    
    const handlepdfExportRows = (rows) => {
      const doc = new jsPDF();
      const tableData = rows.map((row) => Object.values(row.original));
      const tableHeaders = columns.map((c) => c.header);

      let data=rows.map((row) => row.original);
      const columnsToExport = columns.map((c) => c.accessorKey);
       // console.log(columnsToExport)
      // Extract data for the selected columns from each row
      const selectedData = data.map((row) =>
      {//console.log(row);
      return  columnsToExport.map((columnName) => row[columnName])
    });
  
      autoTable(doc, {
        head: [tableHeaders],
        body: selectedData,
      });
  
      doc.save('Energy_'+new Date().toLocaleString()+'.pdf');
    };
  
      const handleExportRows = (rows) => {
        // csvExporter.generateCsv(rows.map((row) => row.original));
        let data=rows.map((row) => row.original);
        const columnsToExport = columns.map((c) => c.accessorKey);
         // console.log(columnsToExport)
        // Extract data for the selected columns from each row
        const selectedData = data.map((row) =>
        {//console.log(row);
        return  columnsToExport.map((columnName) => row[columnName])
      });
       //console.log(selectedData)
        // Generate CSV with the selected data
        csvExporter.generateCsv(selectedData);
      };
    
      const handleExportData = () => {
        csvExporter.generateCsv(tableData2);
      };

  return (
    <>
    <div className="bg-stone-200 py-1 px-4 sm:px-6 lg:px-8 flex items-center">
    <div className="">
      <span className=" text-gray-600 "><Bolt fontSize='large'/></span>
    </div>
    <div className="text-left">
      <h1 className="text-3xl font-semibold text-gray-600">Energy</h1>
    </div>
  </div>
    <div  className='p-4'>
      
      {/* <h2 className="text-2xl font-bold mb-4 text-gray-500">Energy</h2> */}
      <div className="grid grid-cols-4 gap-4 mb-2">
       

        <div className="w-full">
          <label htmlFor="hdropdown" className="block mb-2">
            Select Hostel:
          </label>
          <select id="hdropdown" className="w-full px-4 py-2 border rounded" onChange={handleHostelChange}>
            <option value="" hidden>Select an option</option>
            {hostelData.map((value) => (
              <option key={value.hostel_id} value={value.hostel_id}>
                {value.hostel_id}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full">
          <label htmlFor="dropdown" className="block mb-2">
            Select Room:
          </label>
          <select id="dropdown" className="w-full px-4 py-2 border rounded" onChange={handleRoomChange}>
            <option value="" hidden>Select an option</option>
            <option value="" >All rooms</option>
            {roomData.map((value) => (
              <option key={value.room_id} value={value.room_id}>
                {value.room_no}
              </option>
            ))}
          </select>
        </div>

        <div className="w-full">
          <label htmlFor="datepicker" className="block mb-2">
            Select StartDate:
          </label>
          <div className="relative">
            <input
              type="text"
              id="startdatepicker"
              className="w-full px-4 py-2 pl-10 border rounded"
              onChange={handlestartDateChange}
            />
            <span className="absolute top-0 left-0 flex items-center h-full pl-3">
              {/* Replace the 'icon-code' with the actual icon class or SVG code */}
              <i className="bx bxs-calendar text-gray-500"></i>
            </span>
          </div>
        </div>
        <div className="w-full">
          <label htmlFor="datepicker" className="block mb-2">
            Select Enddate:
          </label>
          <div className="relative">
            <input
              type="text"
              id="enddatepicker"
              className="w-full px-4 py-2 pl-10 border rounded"
              onChange={handleendDateChange}
            />
            <span className="absolute top-0 left-0 flex items-center h-full pl-3">
              {/* Replace the 'icon-code' with the actual icon class or SVG code */}
              <i className="bx bxs-calendar text-gray-500"></i>
            </span>
          </div>
        </div>
        <div className="w-full">
          <label htmlFor="hdropdown" className="block mb-2">
            Enter Rate:
          </label>
          <input
              type="text"
              value={selectRate}
              onChange={(event)=>setselectRate(event.target.value)}
              className="w-full px-4 py-2 pl-10 border rounded"
              
            />
        
        </div>

        <div className="w-full ">
        <label htmlFor="hdropdown" className="block mb-2">
            &nbsp;
          </label>
          {/* <label htmlFor="hdropdown" className="block mb-2">&nbsp;</label> */}
          <button id="submitBtn" className="px-4 py-2 bg-gray-600 text-white font-semibold rounded" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>

      {/* <div id="tableContainer" className="w-full h-60vh overflow-auto custom-div hidden ">
        <table className="w-full bg-white border border-gray-300 shadow-md rounded-lg divide-y divide-gray-200 mt-4">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2 text-left border">Hostel</th>
              <th className="px-4 py-2 text-left border">Room</th>
              <th className="px-4 py-2 text-left border">Start</th>
              <th className="px-4 py-2 text-left border">End</th>
              <th className="px-4 py-2 text-left border">Consumption</th>
            </tr>
          </thead>
          <tbody id="tableBody" className="w-full">
            {tableData2.map((record) => (
              <tr key={record.hour}>
                <td className="px-4 py-2 border">{record.hostel_id}</td>
                <td className="px-4 py-2 border">{record.room_no}</td>
                <td className="px-4 py-2 border">{record.endreadings}</td>
                <td className="px-4 py-2 border">{record.startreadings}</td>
                <td className="px-4 py-2 border">{record.total_consumption_kWh}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}

<MantineReactTable  className=""
     
      enableStickyHeader
      enableStickyFooter
  
      mantineTableContainerProps={{ sx: { maxHeight: '28vh' },xl: { maxHeight: '48vh' } }}
      
        columns={columns}
        data={tableData2}
        enableColumnOrdering
        createDisplayMode='row' // ('modal', and 'custom' are also available)
        editDisplayMode='row' // ('modal', 'cell', 'table', and 'custom' are also available)
        renderTopToolbarCustomActions ={({ table }) => (
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              padding: '8px',
              flexWrap: 'wrap',
            }}
          >
          
          
            {userType === 'admin' && (
        <Button
          className='bg-gray-600'
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          lefticon={<IconDownload />}
          variant="filled"
        >
          Export Csv
        </Button>
      )}
      {userType === 'admin' && (
            <Button
             className='bg-gray-600'
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handlepdfExportRows(table.getPrePaginationRowModel().rows)
          }
          lefticon={<IconDownload />}
          variant="filled"
        >
          Export Pdf
        </Button>
      )}
            {/* <Button
             className='bg-blue-600'
              disabled={table.getRowModel().rows.length === 0}
              //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
              onClick={() => handleExportRows(table.getRowModel().rows)}
              lefticon={<IconDownload />}
              variant="filled"
            >
              Export Page Rows
            </Button> */}
           
          </Box>
        )}
  
      />
    </div>
    </>
  );
};

export default Energy;
