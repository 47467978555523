import React, { useCallback, useMemo, useState,useEffect } from 'react';
import {
  MantineReactTable,
  // createRow,
  useMantineReactTable,
} from 'mantine-react-table';
import { Flex } from '@mantine/core';
import axiosInstance from '../../../utils/customfetch';
import basepath from '../../../constants/basepath';
import { IconDownload } from '@tabler/icons-react';
import { ExportToCsv } from 'export-to-csv'; 
import{Bolt} from '@mui/icons-material';
import { jsPDF } from 'jspdf'; //or use your library of choice here
import autoTable from 'jspdf-autotable';

import { red,grey,dark } from '@mui/material/colors';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { Delete, Edit,Check,Close,Settings } from '@mui/icons-material';
import { data, states } from './makeData';
import { Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
const ConfirmationModal = ({ message, onConfirm, onCancel }) => {
    return (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded shadow-lg">
        <p>{message}</p>
        <div className="mt-4 flex justify-end">
          <button onClick={onCancel} className="btn-cancel">
          <IconButton color="error">
                <Close />
              </IconButton>
           Cancel
          </button>
          <button onClick={onConfirm} className="btn-confirm">
          <IconButton color="success">
                <Check />
              </IconButton>  OK
          </button>
        </div>
      </div>
    </div>
    );
  };
  
const Example = () => {

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState(() => data);
    const [validationErrors, setValidationErrors] = useState({});
    const [row,setRow]=useState([]);
    const [userType, setUserType] = useState('');
    useEffect(() => {
      // Retrieve the user type from local storage
  function fetchusertype(){
      const userTypeFromLocalStorage = localStorage.getItem('userType');
      if (userTypeFromLocalStorage) {
        setUserType(userTypeFromLocalStorage);
      }
  }
  fetchusertype();
    }, []);


    useEffect(() => {
      // Function to fetch data from the API
      const fetchData = async () => {
        try {
          const response = await axiosInstance.get(`${basepath}students`);
          console.log(response)
          setTableData(response);
          // setPlists(myData.Participants); // Set the data to the state
        
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      // Call the fetchData function to load data on component mount
      fetchData();
    }, []); 

    const handleDeleteClick = () => {
      setShowConfirmation(true);
    };
  
    const handleConfirm = async () => {
      // Handle the delete action here

      tableData.splice(row.index, 1);
      setTableData([...tableData])
      setShowConfirmation(false);
      try {
        const response = await axiosInstance.get(`${basepath}delete_students/${row.original.student_id}`);
      
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    const handleCancel = () => {
      setShowConfirmation(false);
    };


  const handleCreateNewRow = (values) => {
    tableData.push(values);
    setTableData([...tableData]);
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      let sendid=tableData[row.index].id
      tableData[row.index] = values;
      //send/receive api updates here, then refetch or update local table data for re-render
      setTableData([...tableData]);
      const source = values; // Replace this with your actual source data.
        console.log(values)
      // Create an empty JavaScript object.
      const studentData = {};
      
      // Assign values from the source to the corresponding properties in the JavaScript object.
      studentData.id = source.id;
      studentData.student_name = source.student_name;
      studentData.room_no = source.room_no;
      studentData.email_id = source.email_id;
      studentData.phone_no = source.phone_no;
      studentData.stud_address = source.stud_address;
      studentData.branch_id = source.branch_id;
      studentData.student_id = source.student_id;
      
      
      
          try {
            await axiosInstance.post(`${basepath}update_students/${sendid}`,studentData);
          
          } catch (error) {
            console.error('Error fetching data:', error);
          }
      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row) => {
  
    setRow(row)
    handleDeleteClick( tableData,row );
      //send api delete request here, then refetch or update local table data for re-render
     
    },
    [tableData],
  );
  const handleSaveCell = async (cell, value) => {
    if (!Object.keys(validationErrors).length) {
    //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here
    tableData[cell.row.index][cell.column.id] = value;
    //send/receive api updates here
    console.log(cell.row.original)
    const source = cell.row.original; // Replace this with your actual source data.

// Create an empty JavaScript object.
const studentData = {};

// Assign values from the source to the corresponding properties in the JavaScript object.
studentData.id = source.id;
studentData.student_name = source.student_name;
studentData.room_no = source.room_no;
studentData.email_id = source.email_id;
studentData.phone_no = source.phone_no;
studentData.stud_address = source.stud_address;
studentData.branch_id = source.branch_id;
studentData.student_id = source.student_id;

setTableData([...tableData]);

    try {
      await axiosInstance.post(`${basepath}update_students/${source.id}`,studentData);
    
    } catch (error) {
      console.error('Error fetching data:', error);
    }
     //re-render with new data
  }
  };

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
       console.log(cell);
      
       return {
         error: validationErrors[cell?.id],
         helperText: validationErrors[cell?.id],
         onBlur: (event) => {
          const isValid =
             cell.column?.id === 'email_id'
               ? validateEmail(event.target.value)
               : cell.column.id === 'student_name'
               ? validateRequired(+event.target.value)
               : validateRequired(event.target.value);
           if (!isValid) {
             //set validation error for cell if invalid
             setValidationErrors({
               ...validationErrors,
               [cell?.id]: `${cell?.column.columnDef.header} is required`,
             });
           } else {
             //remove validation error for cell if valid
             delete validationErrors[cell?.id];
             setValidationErrors({
               ...validationErrors,
             });
           }
         },
       };
    },
    [validationErrors],
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: true,
        enableEditing: false, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
      {
        accessorKey: 'hostel_id',
        header: 'Hostel ',
        enableColumnOrdering: true,
        enableEditing: false, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
      {
        accessorKey: 'room_no',
        header: 'Room No ',
        enableColumnOrdering: true,
        enableEditing: false, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
      {
        accessorKey: 'student_id',
        header: 'Student ID',
        enableColumnOrdering: true,
        enableEditing: true, //disable editing on this column
        enableSorting: true,
        size: 80,
      },
      {
        accessorKey: 'student_name',
        header: 'Student Name',
        size: 140,
        enableEditing: true,
        mantineEditTextInputProps: {
          error: validationErrors.student_name, //highlight mui text field red error color
          helperText: validationErrors.student_name, //show error message in helper text.
          required: true,
          onChange: (event) => {
            const value = event.target.value;
            //validation logic
            if (!value) {
              setValidationErrors((prev) => ({ ...prev, student_name: 'Name is required' }));
            } 
             else {
              delete validationErrors.student_name;
              setValidationErrors({ ...validationErrors });
            }
          
          }
        }
      }
      ,
    
      {
        accessorKey: 'email_id',
        header: 'Email',
        enableEditing: true,
        mantineEditTextInputProps: {
          error: validationErrors.email_id, //highlight mui text field red error color
          helperText: validationErrors.email_id, //show error message in helper text.
          required: true,
          onChange: (event) => {
            const value = event.target.value;
            //validation logic
            if (!value) {
              setValidationErrors((prev) => ({ ...prev, email_id: 'Email is required' }));
            } 
             else {
              delete validationErrors.email_id;
              setValidationErrors({ ...validationErrors });
            }
          
          }
        }
      },
 
      {
        accessorKey: 'phone_no',
        header: 'Phone',
        size: 80,
        enableEditing: true,
        mantineEditTextInputProps: {
          error: validationErrors.phone_no, //highlight mui text field red error color
          helperText: validationErrors.phone_no, //show error message in helper text.
          required: true,
          onChange: (event) => {
            const value = event.target.value;
            //validation logic
            if (!value) {
              setValidationErrors((prev) => ({ ...prev, phone_no: 'Phone Number is required' }));
            } 
             else {
              delete validationErrors.phone_no;
              setValidationErrors({ ...validationErrors });
            }
          
          }
        }
      },
      {
        accessorKey: 'stud_address',
        header: 'Address',
        size: 80,
        enableEditing: true,
        mantineEditTextInputProps:  {
          error: validationErrors.stud_address, //highlight mui text field red error color
          helperText: validationErrors.stud_address, //show error message in helper text.
          required: true,
          onChange: (event) => {
            const value = event.target.value;
            //validation logic
            if (!value) {
              setValidationErrors((prev) => ({ ...prev, stud_address: 'Address is required' }));
            } 
             else {
              delete validationErrors.stud_address;
              setValidationErrors({ ...validationErrors });
            }
          
          }
        }
      },
      {
        accessorKey: 'branch_id',
        header: 'Branch',
        size: 80,
        enableEditing: true,
        mantineEditTextInputProps: {
          error: validationErrors.branch_id, //highlight mui text field red error color
          helperText: validationErrors.branch_id, //show error message in helper text.
          required: true,
          onChange: (event) => {
            const value = event.target.value;
            //validation logic
            if (!value) {
              setValidationErrors((prev) => ({ ...prev, branch_id: 'Branch is required' }));
            } 
             else {
              delete validationErrors.branch_id;
              setValidationErrors({ ...validationErrors });
            }
          
          }
        }
      },
      // {
      //   accessorKey: 'state',
      //   header: 'State',
      //   mantineEditTextInputProps: {
      //     select: true, //change to select for a dropdown
      //     children: states.map((state) => (
      //       <MenuItem key={state} value={state}>
      //         {state}
      //       </MenuItem>
      //     )),
      //   },
      // },
    ],
    [getCommonEditTextFieldProps],
  );

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  
  const csvExporter = new ExportToCsv(csvOptions);
  
  const handlepdfExportRows = (rows) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => Object.values(row.original));
    const tableHeaders = columns.map((c) => c.header);

    let data=rows.map((row) => row.original);
    const columnsToExport = columns.map((c) => c.accessorKey);
     // console.log(columnsToExport)
    // Extract data for the selected columns from each row
    const selectedData = data.map((row) =>
    {//console.log(row);
    return  columnsToExport.map((columnName) => row[columnName])
  });

    autoTable(doc, {
      head: [tableHeaders],
      body: selectedData,
    });

    doc.save('student_record_'+new Date().toLocaleString()+'.pdf');
  };

    const handleExportRows = (rows) => {
      // csvExporter.generateCsv(rows.map((row) => row.original));
      let data=rows.map((row) => row.original);
      const columnsToExport = columns.map((c) => c.accessorKey);
       // console.log(columnsToExport)
      // Extract data for the selected columns from each row
      const selectedData = data.map((row) =>
      {//console.log(row);
      return  columnsToExport.map((columnName) => row[columnName])
    });
     //console.log(selectedData)
      // Generate CSV with the selected data
      csvExporter.generateCsv(selectedData);
    };
  
    const handleExportData = () => {
      csvExporter.generateCsv(tableData);
    };

  return (
    <div className='grid grid-row '>
     <div className="bg-stone-200 py-1 px-4 sm:px-6 lg:px-8 flex items-center">
  <div className="">
    <span className=" text-gray-600 "><Settings fontSize='large'/></span>
  </div>
  <div className="text-left">
    <h1 className="text-3xl font-semibold text-gray-600">Students</h1>
  </div>
</div>
    <div className='ml-4 mt-2' style={{ width: '92vw' }}>
     {showConfirmation && (
        <ConfirmationModal
          message="Are you sure you want to delete?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      <MantineReactTable 
      initialState={{ showColumnFilters: true,columnVisibility: { id: false } }}
      enableStickyHeader
      enableStickyFooter
  
      mantineTableContainerProps={{ sx: { maxHeight: '60vh' } }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            mantineTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        renderTopToolbarCustomActions ={({ table }) => (
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              padding: '8px',
              flexWrap: 'wrap',
            }}
          >
             <Button
            
            onClick={() => setCreateModalOpen(true)}
            variant="contained"
          
          >
            + Add
          </Button>
          
          
            {userType === 'admin' && (
        <Button
         
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          lefticon={<IconDownload />}
          variant="filled"
        >
          Export Csv
        </Button>
      )}
      {userType === 'admin' && (
            <Button
             className='bg-gray-600'
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handlepdfExportRows(table.getPrePaginationRowModel().rows)
          }
          lefticon={<IconDownload />}
          variant="filled"
        >
          Export Pdf
        </Button>
      )}
         </Box>
        )}
  
        columns={columns}
        data={tableData}
        enableColumnOrdering
        createDisplayMode='row' // ('modal', and 'custom' are also available)
        editDisplayMode='row' // ('modal', 'cell', 'table', and 'custom' are also available)
      enableEditing
   
  
      // mantineEditTextInputProps={({ cell }) => ({
      //   //onBlur is more efficient, but could use onChange instead
      //   onBlurCapture: (event) => {
      //     getCommonEditTextFieldProps()
      //     handleSaveCell(cell, event.target.value);
      //   },
      // })}
        // enableRowActions
        renderRowActions={({ row,table }) => (
          <Flex gap="md">
            
           
            {userType === 'sadmin' && (
             <Tooltip  title="Delete">
              <IconButton color="info" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip> )}
            {(userType === 'sadmin' || userType === 'admin') && (
            <Tooltip  title="Edit">
              <IconButton color="info" className='z-5' onClick={() => { table.setEditingRow(row)}}>
                <Edit />
              </IconButton>
            </Tooltip>
            )}
          
          </Flex>
        )}
      // renderBottomToolbarCustomActions={() => (
      //   <Typography sx={{ fontStyle: 'italic', p: '0 1rem' }} variant="body2">
      //     Double-Click a Cell to Edit
      //   </Typography>
      // )}
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        
       
      />
      <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        tableData={tableData}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
    </div>
    </div>
  );
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit,tableData }) => {
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {}),
  );
  const [validationErrors, setValidationErrors] = useState({});
  

  const handleSubmit = async () => {
    //put your validation logic here
    // onSubmit(values);
    // console.log(values);
    // let new_person={student_id:studentID, student_name:studentName, emailid:emailID, phone_no:phoneNumber, stud_address:studentAddress,  branch_id:branchID}

    const { student_name, email_id, phone_no, stud_address, branch_id ,student_id} = values;
    let errors = {};
    if (tableData.some((row) => row.student_id === student_id)) {
      errors.student_id = 'Student ID already exists';
    }
 
   
    if (!validateRequired(student_id)) {
      errors.student_id = 'Student Id is required';
    }

    if (!validateRequired(student_name)) {
      errors.student_name = 'Name is required';
    }

    if (!validateEmail(email_id)) {
      errors.email_id = 'Invalid email format';
    }

    if (!validatePhoneNumber(phone_no)) {
      errors.phone_no = 'Invalid phone number format';
    }

    if (!validateRequired(stud_address)) {
      errors.stud_address = 'Address is required';
    }

    if (!validateRequired(branch_id)) {
      errors.branch_id = 'Branch is required';
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }else{
      setValidationErrors({});
      errors={}
      if(values){
        try {
          onSubmit(values);
          errors={}
      
          await axiosInstance.post(`${basepath}students`,values);
          // setValues({});
          setValues({
            student_name: '',
            email_id: '',
            phone_no: '',
            stud_address: '',
            branch_id: '',
            student_id: '',
        

          });
          setValidationErrors({});
         
        } catch (error) {
          console.error('Error fetching data:', error);
          // setValues({});
          
          setValidationErrors({});
        }
        

    }
   
  }

    onClose();
  };

  return (
    <Dialog open={open}>
    <DialogTitle textAlign="center">
    <AddCircleIcon color='info' sx={{ fontSize: 36 }} />
      Add New Student
    </DialogTitle>
    <DialogContent className="mt-2">
      <form onSubmit={(e) => e.preventDefault()}>
        <Stack
          sx={{
            width: '100%',
            minWidth: { xs: '300px', sm: '360px', md: '400px' },
            gap: '2.5rem',
          }}
        >
       <div className="w-full mt-4 grid grid-cols-2 gap-4">
  {columns.map((column) => {
    return column.enableEditing ? (
      <TextField
        key={column.accessorKey}
        label={column.header}
        name={column.accessorKey}
        className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
        onChange={(e) =>
          setValues({ ...values, [e.target.name]: e.target.value })
        }
        error={validationErrors[column.accessorKey]}
        helperText={validationErrors[column.accessorKey] || ''}
      />
    ) : (
      null
    );
  })}
</div>
        </Stack>
      </form>
    </DialogContent>
    <DialogActions sx={{ p: '1.25rem' }}>
      <Button onClick={onClose} startIcon={<Close />}>Cancel</Button>
      <Button onClick={handleSubmit} variant="contained" startIcon={<SaveIcon />}>
        Save
      </Button>
    </DialogActions>
    
  </Dialog>
  );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
const validateAge = (age) => age >= 18 && age <= 50;
  // Validation Functions


  const validatePhoneNumber = (phoneNumber) =>
    /^(\+\d{1,2}\s?)?(\(\d{3}\)|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phoneNumber);

export default Example;
