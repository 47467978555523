import React,{useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route,Link, Outlet,useLocation ,useNavigate} from 'react-router-dom';
import Login from '../components/login';
import SignUp from '../components/signup';
import Header from '../components/header';
import Footer from '../components/footer';


function Auth() {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
      // Check if the path is empty (only '/' without any additional segments)
      if (location.pathname === '/') {
        // Redirect to '/auth/'
        navigate('/auth/');
      }
    }, [location, navigate]);
  
  return (
    <div className='bg-green-200 flex flex-col max-h-screen'>
      
    


        {/* <Header/> */}
        
        <Outlet />
       <Footer/>
      
     
      
    
     
        
       
    </div>
  
  
  );
}

export default Auth;
