const Dashboardheader = () => {
    return (
        <header className="bg-d1fEC5 text-black px-4 relative flex justify-between items-center">
            <img className="h-6" src="https://hetadatain.com/images/hetadatain.png" alt="" />

            <p className="font-bold text-lg text-black">Billing System for Sawangi Campus</p>

            {/* <img
                className="h-12"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoeN9rTMK_ivjSyUanYYLIQ3I55B0qJdlyimeaJjJiDA&s"
                alt=""
            /> */}
              <div className="h-12">
          <img  className="h-12" src={window.location.origin+'/jnmc.png'} alt=""  />
       </div>
        </header>
    );
}

export default Dashboardheader;
