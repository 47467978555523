import React from 'react';

const EmptyRoomReport = () => {
  const iframeSrc = 'https://sawangibiller.hetadatain.com/api/empty_room_report';

  return (
    <div>
      <h1>Empty Room Report</h1>
      <iframe
        title="Empty Room Report"
        src={iframeSrc}
        width="100%"
        height="500px"
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
};

export default EmptyRoomReport;
