import Footer from "../components/footer";
import Sidebar from "../components/sidebar";
import Content from "../components/content";
import Dashboardheader from "../components/Dasboardheader";
const Dashboard = ()=> {
  


    return (
    <>
     <div className="flex h-screen flex-col  w-full">
  
     <Dashboardheader className="dashboardheader"/>
                <div className=" flex grow w-full dashboardmiddle ">
                
                <div className="sidebarmain">
                <Sidebar/>
                </div>

                <div className="contentmain  w-full">
                <Content/>
            
                </div>

                </div>
     <Footer className="dashboardfooter" />
       
     </div>
   
    </>
)
}
export default Dashboard