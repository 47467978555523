import React from 'react';
import{Bolt} from '@mui/icons-material';

function DeviceDetailsReport() {
  const apiEndpoint = 'https://sawangibiller.hetadatain.com/api/device_info';

  return (
    <div>
      <iframe
        title="Device Details Report"
        src={apiEndpoint}
        width="100%"
        height="500px" // Adjust the height as needed
        frameBorder="0"
      ></iframe>
    </div>
  );
}

export default DeviceDetailsReport;
